export default {
  0: { mask: "0", label: "0 - CÉDULA" },
  1: { mask: "1", label: "1 - RNC" },
  2: { mask: "2", label: "2 - TELÉFONO" },
  3: { mask: "3", label: "3 - CELULAR" },
  4: { mask: "4", label: "4 - Máscara 4" },
  5: { mask: "5", label: "5 - EMAIL" },
  6: { mask: "6", label: "6 - SOLO NUMERO" },
  7: { mask: "7", label: "7 - SOLO LETRAS" },
  8: { mask: "8", label: "8 - Máscara 8" },
  9: { mask: "9", label: "9 - EMAIL DIST. SOLICITANTE" },
  10: { mask: "10", label: "10 - FECHA" },
  11: { mask: "11", label: "11 - HORA" },
  12: { mask: "12", label: "12 - DECIMAL" },
  13: { mask: "13", label: "13 - RUT" },
  14: { mask: "14", label: "14 - Validación Prórroga Listados" },
  15: { mask: "15", label: "15 - Validación reconsideración Clasificación" },
  16: { mask: "16", label: "16 - Resolución Listados de Confotur" },
  17: { mask: "17", label: "17 - LATITUD" },
  18: { mask: "18", label: "18 - LONGITUD" },
  19: { mask: "19", label: "19 - MAYUSCULAS" },
  20: { mask: "20", label: "20 - Código DPP" },
  21: { mask: "21", label: "21 - Código MIMARENA" },
  22: { mask: "22", label: "22 - FECHA NO MAYOR ACTUAL" },
  23: { mask: "23", label: "23 - FECHA NO MENOR ACTUAL" },
  22: { mask: "24", label: "24 - Validación Prórroga No Objeción Uso de Suelo" },
 
};
