import React, { Component } from "react";
import { MDBCol, MDBIcon, MDBRow } from "mdbreact";
import CardForm from "./cardForm";
import NewForm from "./btnNewForm";
import NewFormBK from "./btnNewFormBK";
import { Form } from "../../data/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";

const { GetAllFormulary, GetAllFormularyDataTable } = Form;

class AllFormulary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      formExist: false,
      message: false,
      gettingErrors: true,
      datagetter: true,
      dataOld: [],
      forms: [],
      loading: false,
      setLoading: false,
      currentPage: 1,
      setCurrentPage: 1,
      formsPerPage: 40,
      setFormsPerPage: 40,
      currentForms: [],
    };
    this.dataForm = this.dataForm.bind(this);
  }

  async componentDidMount() {
    const dataGetter = await GetAllFormularyDataTable();
    if (dataGetter.ok) {
      this.setState({
        gettingErrors: false,
      });
    }
    try {
      if (dataGetter.data.Formulary.length > 0) {
        this.setState({
          data: dataGetter.data.Formulary,
          dataOld: dataGetter.data.Formulary,
        });
      }
    } catch (e) {}
  }

  async dataForm() {
    try {
      if (this.state.data.length > 0) {
        const dataGetter = await GetAllFormulary();
        if (dataGetter.data.Formulary.length === 0) {
          this.setState({
            message: true,
          });
        }
        this.setState({
          data: dataGetter.data.Formulary,
          setForms: dataGetter.data.Formulary,
        });
      }
    } catch (e) {
      return false;
    }
  }

  componentWillMount() {
    if (this.props.location.state) {
      toast.error(
        "Tienes que crear el formulario [" +
          this.props.location.state.idForm +
          "] antes de crear sus reglas",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }

  render() {
    return (
      <div className="App padding">
        <MDBRow>
          <MDBCol md="3">
            <NewForm handlerData={this.dataForm} />
          </MDBCol>
          <MDBCol md="3">
            <NewFormBK handlerData={this.dataForm} />
          </MDBCol>
          <MDBCol md="3"></MDBCol>
          <MDBCol md="3"></MDBCol>
        </MDBRow>
        <hr />
        <br />
        {this.state.datagetter === false && <h3>Error de conexion</h3>}
        {this.state.gettingErrors === false && this.state.data.length <= 0 && (
          <div className="col-md-12">
            <b>
              <h3>No hay formularios registrados</h3>
            </b>
          </div>
        )}
        {this.state.gettingErrors && this.state.datagetter === true && (
          <div className="col-md-12">
            <MDBIcon
              icon="cog"
              spin
              size="3x"
              style={{
                color:
                  config.env === "production"
                    ? "#00695c"
                    : config.env === "development"
                    ? "#0d47a1"
                    : "#424242",
              }}
              fixed
            />
            <br></br>
            <br></br>
            <b>
              <h3>Cargando...</h3>
            </b>
          </div>
        )}
        {this.state.data.length > 0 && <CardForm data={this.state.data} />}
        {this.state.data.length <= 4 && (
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default AllFormulary;
