import { Component } from "react";
import { MDBCard, MDBCol, MDBIcon, MDBRow } from "mdbreact";
import config from "../../config";
import { Form } from "../../data/index";
import Chart from "./chart";
import ChartsForm from "./chartForm";
import _ from "lodash";

const { GetAllFormularyDataTable } = Form;

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bk: "",
      se: "",
      inst: 0,
      servicesTotal: 0,
      top5Services: [],
      lastServices: [],
      noData: false,
    };
  }

  async componentDidMount() {
    const result = await GetAllFormularyDataTable();
    if (result.data.Formulary) {
      this.filterData(result.data.Formulary);
      this.topInstitutions(result.data.Formulary);
    } else {
      this.setState({
        noData: true,
      });
    }
  }

  filterData(data) {
    this.setState({
      lastServices: data.slice(Math.max(data.length - 4, 1)),
      servicesTotal: data.length,
      bk: this.filterForm(data)[0].length,
      se: this.filterForm(data)[1].length,
      top5Services: this.topInstitutions(data),
      inst: this.filterInstitution(data).length,
    });
  }

  filterInstitution(data) {
    let result = [];
    for (const key in data) {
      result.push(data[key].idinstitution);
    }
    return result.filter(function (item, index) {
      return result.indexOf(item) >= index;
    });
  }

  filterForm(data) {
    let se = [];
    let bk = [];
    for (const key in data) {
      if (data[key].provider === "bk") {
        bk.push(data[key]);
      } else {
        se.push(data[key]);
      }
    }
    return [bk, se];
  }

  topInstitutions(data) {
    return _.countBy(data, (forms) => forms.institution_name);
  }

  render() {
    if (this.state.noData) {
      return (
        <div>
          <h1>Usted no esta logueado</h1>
        </div>
      );
    }

    return (
      <div className="bg">
        <br />
        <MDBRow>
          <MDBCol sm="6">
            <MDBCard className="padding">
              <ChartsForm se={this.state.se || 1} bk={this.state.bk || 1} />
            </MDBCard>
          </MDBCol>
          <MDBCol sm="6">
            <MDBCard>
              {this.state.lastServices.length <= 0 && (
                <MDBIcon
                  icon="cog"
                  spin
                  size="3x"
                  style={{
                    color:
                      config.env === "production"
                        ? "#00695c"
                        : config.env === "development"
                        ? "#0d47a1"
                        : "#424242",
                  }}
                />
              )}
              <Chart services={this.state.top5Services} />
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <br />
        <hr />
        <br />

        <MDBRow>
          <MDBCol>
            <MDBCard>
              <MDBRow around middle className="padding">
                <MDBIcon icon="building" size="10x" style={{ color: "rgb(116,180,12,0.8)" }} />
                <label style={{ fontSize: 135, color: "rgb(116,180,12,0.8)" }}>
                  {this.state.inst}
                </label>
              </MDBRow>
              <label style={{ fontSize: 25 }}>Total de instituciones</label>
            </MDBCard>
          </MDBCol>
          <MDBCol>
            <MDBCard>
              <MDBRow around middle className="padding">
                <MDBIcon icon="hand-holding-usd" size="10x" style={{ color: "rgb(28,36,108,1)" }} />
                <label style={{ fontSize: 135, color: "rgb(28,36,108,1)" }}>
                  {this.state.servicesTotal}
                </label>
              </MDBRow>
              <label style={{ fontSize: 25 }}>Total de servicios</label>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <br />
        <hr />
        <br />
        <h4>Ultimos servicios:</h4>
        <MDBRow>
          {this.state.lastServices.length <= 0 && (
            <div className="col-md-12">
              <MDBIcon
                icon="cog"
                spin
                size="3x"
                style={{
                  color:
                    config.env === "production"
                      ? "#00695c"
                      : config.env === "development"
                      ? "#0d47a1"
                      : "#424242",
                }}
                fixed
              />
              <br></br>
              <br></br>
              <b>
                <h5>Cargando servicios...</h5>
              </b>
            </div>
          )}
          {this.state.lastServices.length > 0 &&
            this.state.lastServices.map((service, i) => (
              <MDBCol key={i}>
                <MDBCard className="padding">
                  <center>
                    <img
                      height="100"
                      width="120"
                      src={
                        service.url
                          ? service.url
                          : "https://zenit.org/wp-content/uploads/2018/05/no-image-icon.png"
                      }
                      alt={"no icon"}
                    />
                    <br />
                    <label className="d-inline-block text-truncate" style={{ maxWidth: "300px" }}>
                      {service.service_name}
                    </label>
                  </center>
                </MDBCard>
              </MDBCol>
            ))}
        </MDBRow>

        <br />
        <hr />
        <br />
        <MDBRow>
          <MDBCol>
            <MDBCard></MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default Home;
