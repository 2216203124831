import React, { Component } from "react";

import {
  MDBBtn,
  MDBBtnGroup,
  MDBCol,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBProgress,
  MDBRow,
} from "mdbreact";

import {
  cleanForm,
  convertConfigurations,
  CreateRuleCheckbox,
  CreateRuleRadioButton,
  CreateRuleSelect,
  CreateRuleText,
  MaskConfig,
  userData,
} from "../../Helpers/helpers";
import { toast } from "react-toastify";
import "./form.css";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config";
import {
  Backend,
  Configuration,
  Form,
  Mask,
  Roles,
  Rules,
  RulesInit,
  RulesPrice,
  Softexpert,
} from "../../data/index";

import { GetRulesInitById } from "../../data/collections/rulesinit";
import axios from "axios";
import { LOCATION_MASK } from "../Masks/jsonData";

const { GetFormularyById } = Form;
const { GetRulesById, GetRulesByForm } = Rules;
const { GetRulesInitSelected } = RulesInit;
const { GetMaskVersionById, GetAllMaskVersionsPerForm } = Mask;
const { GetForms, validateForm } = Softexpert;
const { GetConfigurations } = Configuration;
const { GetRulesPriceByForm } = RulesPrice;
const { GetRolesByUserAndForm } = Roles;
const { GetLastVersionFormByService, sendForm, GetFormularyByServiceBK, GetServiceById } = Backend;

const fetcher = async (url) => {
  return await axios.get(url).then((res) => {
    return res.data;
  });
};

const setLocationMaskValues = async (fields) => {
  for (const key in fields[0]) {
    if (LOCATION_MASK.includes(fields[0][key].Mask)) {
      if (fields[0][key]?.parentComponent) {
        fields[0][key].father_id = fields[0][key].parentComponent;
      }

      switch (fields[0][key].Mask) {
        case "51":
          console.log("Setting countries mask");
          const countries = await fetcher(`${process.env.REACT_APP_API_DATA}/countries`);

          fields[0][key].values = countries?.map((item) => {
            return {
              value: item.oid,
              label: item.pais,
            };
          });
          break;
        case "52":
          console.log("Setting provinces mask");
          const provinces = await fetcher(`${process.env.REACT_APP_API_DATA}/provinces`);

          fields[0][key].values = provinces?.map((item) => {
            return {
              father: item.padre,
              value: item.oid,
              label: item.provincia,
            };
          });
          break;
        case "53":
          console.log("Setting municipalities mask");
          const municipalities = await fetcher(`${process.env.REACT_APP_API_DATA}/municipalities`);

          fields[0][key].values = municipalities?.map((item) => {
            return {
              father: item.padre,
              value: item.oid,
              label: item.municipio,
            };
          });
          break;
        case "54":
          console.log("Setting sectors mask");
          const sectors = await fetcher(`${process.env.REACT_APP_API_DATA}/sectors`);

          fields[0][key].values = sectors?.map((item) => {
            return {
              father: item.padre,
              value: item.oid,
              label: item.sector,
            };
          });
          break;
        default:
          console.warn("Mask not of type Location");
      }
    }
  }

  return fields;
};

class cardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalJSON: false,
      message: "",
      error: false,
      loading: false,
      loadingForRule: false,
      shadow: "",
      arrayRule: [],
      arrayMask: [],
      arrayWithMaskParams: [],
      json: "",
      submit: true,
      jsonBySE: "",
      lastVersion: 0,
      loadingData: false,
      maskChange: false,
      rulesChange: false,
      modalError: false,
      modalConfig: false,
      modalErrorJson: false,
      validate: false,
      modalLoading: false,
      progress: 0,
      errorSE: [],
      color: { ruleInit: "#424242", rulePrice: "#424242", config: "#424242" },
      errorJson: [],
      form: [],
    };
  }

  toggle = async (id) => {
    if (!this.state.modal) {
      const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"), id);
      if (!roles.data.rol) {
        this.setState({
          progress: 0,
        });
        toast.error("Usted no tiene permiso para ver este formulario", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return;
      }
    }

    this.setState({
      modalLoading: true,
      color: { ruleInit: "#424242", rulePrice: "#424242", config: "#424242" },
    });

    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
        modalLoading: false,
      });
      return;
    }

    const dataForm = await GetFormularyById(id);
    let form = dataForm.data.Formulary[0];
    await this.infoForm(form);

    //==========================GET FORM FROM SOFTEXPERTAPI============================

    if (form.provider === "bk") {
      const formBk = await GetFormularyByServiceBK(form.idservice);
      if (formBk.ok) {
        let jsonForm = [];
        jsonForm[0] = JSON.parse(formBk.data.Form.fields);
        jsonForm[0] = cleanForm(jsonForm[0]);

        this.setState({
          modal: !this.state.modal,
          modalLoading: false,
          jsonBySE: formBk.data && jsonForm,
          form: form,
          lastVersion: form.versionForm,
          validate: form.validate !== null ? form.validate !== 1 : false,
        });

        await this.Actions(id);
        return;
      }

      this.setState({
        errorJson: formBk.data,
        modalErrorJson: true,
        modalLoading: false,
      });
    }

    const formSE = await GetForms(form.idForm);

    if (formSE.error || !formSE.ok) {
      this.setState({
        errorJson: formSE,
        modalErrorJson: true,
        modalLoading: false,
      });
      return;
    }

    this.setState({
      modal: !this.state.modal,
      modalLoading: false,
      jsonBySE: formSE.data && formSE.data,
      form: form,
      lastVersion: form.versionForm,
      validate: form.validate !== null ? form.validate !== 1 : false,
    });

    await this.Actions(id);
  };

  modalErrorJson = () => {
    this.setState({
      modalErrorJson: !this.state.modalErrorJson,
    });
  };

  toggleError = () => {
    this.setState({
      modalError: !this.state.modalError,
    });
  };

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async Actions(id) {
    this.setState({
      modalConfig: true,
      progress: 5,
    });

    // ============ set rule init======================
    await this.sleep(2000);
    let ruleInit = await this.ruleInit(id);
    if (ruleInit) {
      this.state.color["ruleInit"] = "green";
    }

    this.setState({ progress: 30 });

    // =========== set rules prices=====================
    await this.sleep(2000);
    let rulePrice = await this.setRulesPrice(id);
    if (rulePrice) {
      this.state.color["rulePrice"] = "green";
    }

    this.setState({ progress: 70 });
    // ============ set configurations fields ========
    await this.sleep(2000);
    let config = await this.setConfigurations(id);
    if (config) {
      this.state.color["config"] = "green";
    }

    await this.sleep(1000);
    this.setState({ progress: 100 });

    await this.sleep(1000);
    this.setState({ modalConfig: false });
  }

  async saveForm() {
    this.setState({
      loading: true,
    });

    for (const key in this.state.json[0]) {
      if (this.state.json[0][key].userElement) {
        delete this.state.json[0][key];
      }
    }

    this.state.json[0] = this.state.json[0].filter(function (el) {
      return el != null;
    });

    let fields = this.state.json;

    if (fields[0].some((field) => LOCATION_MASK.includes(field.Mask))) {
      fields = await setLocationMaskValues(fields);
    }

    let body = {
      name: this.state.form.idForm,
      fields: fields,
      service_id: this.state.form.idservice,
      institution_id: this.state.form.idinstitution,
      version: this.state.lastVersion,
      entity: this.state.form.entity,
      bk: this.state.form.provider === "bk",
      description: "Formulario enviando desde app de reglas con version:" + this.state.lastVersion,
    };

    const result = await sendForm(body);

    if (result.ok) {
      const lastVersion = await GetLastVersionFormByService(this.state.form.idservice);

      this.setState({
        message: result.data.message,
        error: false,
        modal: false,
        loading: false,
        lastVersion: lastVersion.data.version ? lastVersion.data.version : 1,
      });

      toast.success("Se envio Correctamente el formulario: " + this.state.form.idForm, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      this.setState({
        message: result.data.message,
        error: true,
        loading: false,
        modalError: true,
      });
      toast.error("Error al enviar el formulario!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }

  fields(data, rule) {
    for (const i in data) {
      for (const key in data[i]) {
        if (
          data[i][key].type === "text" ||
          (data[i][key].type === "date") |
            (data[i][key].type === "time") |
            (data[i][key].type === "textarea") |
            (data[i][key].type === "file")
        ) {
          CreateRuleText(data, i, key, rule);
          if (data[i][key].name === "texto50123") {
            data[i][key].quantity = true;
          }
        }
        if (data[i][key].type === "radio-group") {
          CreateRuleRadioButton(data, i, key, rule);
        }
        if (data[i][key].type === "select") {
          CreateRuleSelect(data, i, key, rule);
        }
        if (data[i][key].type === "checkbox-group") {
          CreateRuleCheckbox(data, i, key, rule);
        }
      }
    }
    return data;
  }

  convertRule(result) {
    let array = [];
    let arrayRule = result.data.rules[0].rule.split("&");
    for (const i in arrayRule) {
      const element = arrayRule[i].split("=")[0];
      array[element] = arrayRule[i];
    }
    return array;
  }

  async setMaskVersion(e) {
    if (e.target.value === "0") {
      this.setState({
        maskChange: false,
      });
      return;
    }
    if (e.target.value === "noMask") {
      if (this.state.json.length > 0) {
        for (const i in this.state.json[0]) {
          if (this.state.json[0][i].type === "text") {
            this.state.json[0][i].Mask = "";
          }
        }
      }

      this.setState({
        maskChange: true,
      });
      return;
    }

    if (e.target.value === "seMask") {
      this.setState({
        maskChange: true,
      });

      return;
    }

    const resultMask = await GetMaskVersionById(e.target.value);
    let jsonMask = MaskConfig(resultMask, this.state.json);

    if (!jsonMask.ok) {
      this.setState({
        modalError: true,
        errorSE: [
          {
            nombre: "Esta version de mascara es antigua",
            id: "error 202",
            value: "mascara obsoleta",
            detail:
              "Este error sucede cuando existe una mascara con la version antigua, para resolver este problema vuelva a la seccion de mascara y guarde nuevamente esta version. Para ver los pasos de solucion ir a /Documentacion/Mascaras/ 1.5 - mascaras obsoletas ",
          },
        ],
      });
    }

    if (jsonMask.ok) {
      this.setState({
        json: jsonMask.json,
        maskChange: true,
      });
    }
  }

  async SaveJson(e) {
    if (e.target.value === "0") {
      document.getElementById("maskSelect").setAttribute("disabled", false);
      this.setState({
        rulesChange: false,
      });
      return;
    }
    document.getElementById("maskSelect").removeAttribute("disabled", false);
    if (e.target.value === "noRule") {
      // document.getElementById("maskSelect").setAttribute("disabled",true)
      this.setState({
        rulesChange: true,
        json: this.state.jsonBySE,
      });
      return;
    }

    const resultRule = await GetRulesById(e.target.value);
    const rule = this.convertRule(resultRule);
    let data = await this.fields(this.state.jsonBySE, rule);

    this.setState({
      json: data,
      rulesChange: true,
    });
  }

  async validate() {
    this.setState({
      loading: true,
      maskChange: false,
      validate: true,
    });

    if (this.state.form.provider === "bk") {
      this.setState({
        loading: false,
        maskChange: true,
        validate: true,
        modalError: false,
      });
      toast.info("Este formulario no proviene desde softexpert", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    let fail = [];
    let arrayError = [];
    console.log("this.state.json[0]", this.state.json[0]);
    Array.prototype.push.apply(this.state.json[0], userData());

    const servicesInfo = await GetServiceById(this.state.form.idservice);
    console.log("servicesInfo******", servicesInfo);
    let body = {
      fields: this.state.json,
      entity: this.state.form.entity,
      process_id: servicesInfo.data.process,
    };
    const form = await GetForms(this.state.form.idForm);
    console.log("form", form);

    if (form.error) {
      this.setState({
        errorJson: form,
      });
      return;
    }
    console.log("!!!!body", body);
    const result = await validateForm(body);
    console.log(")()()(())()(()body", result);
    for (const key in result.data[0]) {
      if (result.data[0][key].Code !== "1") {
        fail.push(result.data[0][key]);
      }
    }
    if (fail.length > 0) {
      for (const key in fail) {
        if (fail[key].Campo) {
          arrayError.push({
            nombre: fail[key].Campo.Nombre,
            id: fail[key].Campo.id,
            value: fail[key].Campo.sentValue,
            detail: fail[key].Detail,
          });
        }
      }
      console.log("errores :", fail.toString());

      this.setState({
        validate: false,
        modalError: true,
        errorSE:
          arrayError.length > 0
            ? arrayError
            : [
                {
                  nombre: "softexpert",
                  id: "error",
                  value: "ninguno",
                  detail: fail.toString(),
                },
              ],
      });
    } else {
      this.setState({
        validate: true,
        modalError: false,
        errorSE: arrayError,
      });
      toast.success("El formulario funciona correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      loading: false,
      maskChange: true,
    });
  }

  async ruleInit(id) {
    const result = await GetRulesInitSelected(id);
    let json = this.state.jsonBySE;
    this.deleteJson(json);
    if (result.data.rules) {
      for (const key in json) {
        for (const i in json[key]) {
          if (json[key][i].type === "rules") {
            json[key][i].rules[0] = result.data.rules.rule;
          } else {
            this.validateRulesInitJson(json[0], result.data.rules.rule);
          }
        }
      }
      return true;
    } else {
      for (const key in json) {
        for (const i in json[key]) {
          if (json[key][i].type === "rules") {
            json[key][i].rules = [];
          } else {
            this.validateRulesInitJson(json[0], []);
          }
        }
      }
      return false;
    }
  }

  async componentDidMount() {
    this.setState({
      data: this.table(this.props.data),
    });
  }

  async infoForm(form) {
    this.setState({
      loadingData: true,
    });

    const result = await GetRulesByForm(form.id);
    const lastVersion = await GetLastVersionFormByService(form.idservice);
    const maskVersions = await GetAllMaskVersionsPerForm(form.id);

    this.setState({
      arrayRule: result.data.rules,
      lastVersion: lastVersion.data.version ? lastVersion.data.version : 1,
      arrayMask: maskVersions.data.mask,
      loadingData: false,
    });
  }

  table(data) {
    let array = [];
    try {
      for (const i of data) {
        let element = {
          id: i.id,
          idForm: i.idForm,
          logo: (
            <img
              alt=""
              className={" hoverable img-fluid"}
              width="80"
              src={i.url ? i.url : require("../../img/test.png")}
            />
          ),
          service: i.service_name,
          institution: i.institution_name,
          Accion: [
            <MDBBtnGroup key={"btn" + i.id}>
              <MDBBtn
                className="btn  btn-sm text-white"
                color={config.color}
                onClick={() => this.toggle(i.id)}
              >
                Enviar
              </MDBBtn>
            </MDBBtnGroup>,
          ],
        };
        array.push(element);
      }
    } catch (e) {}
    const column = [
      { label: "id", field: "id", sort: "asc" },
      { label: "formulario", field: "idForm", sort: "asc" },
      { label: "logo", field: "logo", sort: "asc" },
      { label: "Servicio", field: "service", sort: "asc" },
      { label: "Institucion", field: "institution", sort: "asc" },
      { label: "Accion", field: "Accion", sort: "asc" },
    ];

    return { columns: column, rows: array };
  }

  validateRulesPriceJson(json, data) {
    let rulesprice = false;
    for (const i in json) {
      if (json[i].type === "rulesprice") {
        json[i].data = data;
        rulesprice = true;
      }
    }
    !rulesprice && json.push({ type: "rulesprice", data: data });
  }

  validateRulesInitJson(json, data) {
    let rulesinit = false;
    for (const i in json) {
      if (json[i].type === "rules") {
        json[i].data = data;
        rulesinit = true;
      }
    }
    !rulesinit && json.push({ type: "rules", data: data });
  }

  deleteJson(json) {
    for (const key in json) {
      if (json[key].type === "rulesprice") {
        delete json[key];
      }
    }
  }

  async setRulesPrice(id) {
    let prices = [];
    const result = await GetRulesPriceByForm(id);

    try {
      if (result.data.rules.length > 0) {
        let data = result.data.rules;
        for (const key in data) {
          if (data[key].selected === 1) {
            const ruleinit = await GetRulesInitById(data[key].idRuleinit);
            prices.push({
              id: data[key].idPrice,
              type: data[key].typePrice,
              rules: ruleinit.data.rules.rule,
              attribute: data[key].attribute ? data[key].attribute : "&",
            });
          }
        }

        this.validateRulesPriceJson(this.state.jsonBySE[0], prices);
        return true;
      }
    } catch (e) {}
    return false;
  }

  async setConfigurations(id) {
    let page = this.state.jsonBySE;
    let config = [];
    const result = await GetConfigurations(id);
    if (result.data.configurations) {
      config = convertConfigurations(result);

      for (const key in page) {
        let fields = page[key];

        for (const i in fields) {
          for (const key in config.json) {
            if (key.search("_") === -1 && fields[i].name === key) {
              fields[i].enabled !== null && (fields[i].enabled = !config.json[key].disabled);
              fields[i].hidden !== null && (fields[i].hidden = config.json[key].hidden);
              fields[i].required !== null && (fields[i].required = config.json[key].require);
            }

            if (key.search("_") !== -1 && fields[i].name === key.split("_")[0]) {
              let dataGrid = fields[i].fields;
              let dataGridConfig = config.json[key];
              for (const key in dataGrid) {
                for (const i in dataGridConfig) {
                  if (dataGrid[key].name === i) {
                    dataGrid[key].enabled !== null &&
                      (dataGrid[key].enabled = !dataGridConfig[i].disabled);
                    dataGrid[key].hidden !== null &&
                      (dataGrid[key].hidden = dataGridConfig[i].hidden);
                    dataGrid[key].required !== null &&
                      (dataGrid[key].required = dataGridConfig[i].require);
                  }
                }
              }
            }
          }
        }
      }

      this.setState({
        jsonBySE: page,
      });

      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="App padding">
        <MDBContainer>
          <MDBDataTable bordered hover data={this.state.data} />
        </MDBContainer>
        {/** ===========================MODAL===================================*/}
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
            <MDBModalHeader toggle={this.toggle}>
              Formulario: {this.state.form.idForm}
            </MDBModalHeader>

            {this.state.loadingData ? (
              <div>
                <MDBIcon
                  icon="cog"
                  spin
                  size="2x"
                  style={{
                    color:
                      config.env === "production"
                        ? "#00695c"
                        : config.env === "development"
                        ? "#0d47a1"
                        : "#424242",
                  }}
                  fixed
                />
                <label>cargando....</label>
              </div>
            ) : (
              <MDBModalBody id="modal-body-card-form">
                <p className="h4 text-center mb-4">Enviar Formulario al Portal</p>
                <MDBRow>
                  <MDBCol>
                    <br />
                    <label htmlFor="defaultFormLoginEmailEx">Version del formulario</label>
                    <input
                      type="number"
                      id="id_service"
                      name="id_service"
                      className="form-control"
                      onChange={this.idService}
                      value={this.state.lastVersion}
                      disabled
                    />
                  </MDBCol>
                  <MDBCol>
                    <br />
                    <label htmlFor="defaultFormLoginEmailEx">Id formulario</label>
                    <input
                      type="text"
                      id="id_formulary"
                      name="id_formulary"
                      className="form-control"
                      onChange={this.idForm}
                      value={this.state.form.idForm}
                      disabled
                    />
                    <br />
                  </MDBCol>
                </MDBRow>
                <label htmlFor="defaultFormLoginEmailEx">servicio</label>
                <input
                  type="text"
                  id="id_service"
                  name="id_service"
                  className="form-control"
                  onChange={this.idService}
                  value={this.state.form.service_name}
                  disabled
                />
                <br />
                <label htmlFor="defaultFormLoginEmailEx">institucion</label>
                <input
                  type="text"
                  id="id_institution"
                  name="id_institution"
                  className="form-control"
                  onChange={this.idInsti}
                  value={this.state.form.institution_name}
                  disabled
                />
                <MDBRow>
                  <br />
                  <MDBCol>
                    <br />
                    <label htmlFor="defaultFormLoginEmailEx">Version de Regla</label>
                    <select
                      onChange={(e) => this.SaveJson(e)}
                      id="ruleSelect"
                      className="browser-default custom-select"
                    >
                      <option value="0">Seleccione un version</option>
                      {typeof this.state.arrayRule !== "undefined" &&
                        this.state.arrayRule.length > 0 &&
                        this.state.arrayRule.map((rules, i) => (
                          <option key={i} value={rules.id}>
                            Version: {rules.versionRule} ||
                            {rules.createdAt && rules.createdAt.split("T")[0] + " "}
                            {rules.createdAt.split("T")[1].substring(0, 5)}
                          </option>
                        ))}

                      <option value="noRule">Enviar sin reglas</option>
                    </select>
                    {this.state.loadingForRule && (
                      <div>
                        <MDBIcon
                          icon="cog"
                          spin
                          size="2x"
                          style={{
                            color:
                              config.env === "production"
                                ? "#00695c"
                                : config.env === "development"
                                ? "#0d47a1"
                                : "#424242",
                          }}
                          fixed
                        />
                        <label>Asignando regla....</label>
                      </div>
                    )}
                  </MDBCol>
                  <MDBCol>
                    <br />
                    <label htmlFor="defaultFormLoginEmailEx">Version de Máscara</label>
                    <select
                      onChange={(e) => this.setMaskVersion(e)}
                      id="maskSelect"
                      disabled
                      className="browser-default custom-select"
                    >
                      <option value="0">Seleccione un version de Máscara</option>
                      {this.state.arrayMask !== undefined &&
                        this.state.arrayMask.length > 0 &&
                        this.state.arrayMask.map((masks, i) => (
                          <option key={i} value={masks.id}>
                            Version:{masks.maskVersion} ||{" "}
                            {masks.createdAt && masks.createdAt.split("T")[0] + " "}
                            {masks.createdAt.split("T")[1].substring(0, 5)}
                            {masks.createdAt.split("T")[0] < "2020-02-21" && " - mascara obsoleta"}
                          </option>
                        ))}
                      <option value="noMask">Enviar sin máscara</option>
                      <option value="seMask">Enviar con mascaras de softexpert</option>
                    </select>
                  </MDBCol>
                </MDBRow>
                <br />

                <br />
              </MDBModalBody>
            )}
            <MDBModalFooter>
              {this.state.error && (
                <center>
                  <label className="red-text">{this.state.message}</label>
                </center>
              )}
              {this.state.loading && (
                <MDBIcon
                  icon="cog"
                  spin
                  size="3x"
                  style={{
                    color:
                      config.env === "production"
                        ? "#00695c"
                        : config.env === "development"
                        ? "#0d47a1"
                        : "#424242",
                  }}
                  fixed
                />
              )}
              <MDBBtn
                hidden={!!this.state.validate}
                outline
                color={config.color}
                disabled={!(this.state.maskChange && this.state.rulesChange)}
                onClick={() => this.validate()}
              >
                Validar Formulario
              </MDBBtn>
              <MDBBtn
                color={config.color}
                disabled={!(this.state.maskChange && this.state.rulesChange && this.state.validate)}
                onClick={() => this.saveForm()}
              >
                Enviar al portal
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/** ===========================MODAL ERROR===================================*/}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalError}
            toggle={this.toggleError}
            side
            position="bottom-right"
          >
            <MDBModalHeader toggle={this.toggleError}>Errores del formulario</MDBModalHeader>
            <MDBModalBody>
              {this.state.errorSE.length > 0 &&
                this.state.errorSE.map((field) => (
                  <div>
                    <label>{field.nombre + " - " + field.id}</label>
                    <br />
                    <label>Valor enviado: {field.value}</label>
                    <br />
                    <label>Detalle: {field.detail}</label>
                    <br />
                    <hr />
                  </div>
                ))}
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/** ===========================MODAL ERROR SOFTEXPERTAPI===================================*/}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalErrorJson}
            toggle={this.modalErrorJson}
            side
            position="bottom-right"
          >
            <MDBModalHeader toggle={this.modalErrorJson}>
              {this.state.errorJson.error}
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <p className="text-justify">
                  Solucion: <b>{this.state.errorJson.data}</b>
                </p>
                <p className="text-justify">
                  campo:{" "}
                  <b>
                    {(this.state.errorJson.field && this.state.errorJson.field.name) ||
                      "no asignado"}
                  </b>
                </p>
                <p className="text-justify">
                  mascara:{" "}
                  <b>
                    {(this.state.errorJson.field && this.state.errorJson.field.Mask) ||
                      "no asignado"}
                  </b>
                </p>
                <p className="text-justify">
                  label:{" "}
                  <b>
                    {(this.state.errorJson.field && this.state.errorJson.field.label) ||
                      "no asignado"}
                  </b>
                </p>
                <p className="text-justify">
                  grupo:{" "}
                  <b>
                    {(this.state.errorJson.field && this.state.errorJson.field.group) ||
                      "no asignado"}
                  </b>
                </p>
              </div>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        {/** ===========================MODAL LOADING===================================*/}
        <MDBContainer>
          <MDBModal isOpen={this.state.modalLoading} position="bottom-center">
            <MDBModalHeader>cargando formulario....</MDBModalHeader>
            <MDBModalBody>
              <MDBIcon
                icon="cog"
                spin
                size="3x"
                style={{
                  color:
                    config.env === "production"
                      ? "#00695c"
                      : config.env === "development"
                      ? "#0d47a1"
                      : "#424242",
                }}
                fixed
              />
            </MDBModalBody>
          </MDBModal>
        </MDBContainer>

        {/** ===========================MODAL PROGRESS ACTIONS===================================*/}
        <MDBContainer>
          <MDBModal
            isOpen={this.state.modalConfig}
            toggle={() => {}}
            size="lg"
            position="bottom-right"
          >
            <MDBModalHeader>ASIGNANDO CONFIGURACIONES</MDBModalHeader>
            <MDBModalBody>
              <div>
                <MDBRow>
                  <MDBCol>
                    <MDBIcon
                      icon="mouse-pointer"
                      style={{
                        color:
                          config.env === "production"
                            ? "#00695c"
                            : config.env === "development"
                            ? "#0d47a1"
                            : "#424242",
                      }}
                      size="2x"
                    />
                    &emsp;
                    <MDBIcon icon="check" style={{ color: this.state.color.ruleInit }} size="1x" />
                    <br />
                    Reglas de cargas
                  </MDBCol>
                  <MDBCol>
                    <MDBIcon
                      icon="dollar-sign"
                      style={{
                        color:
                          config.env === "production"
                            ? "#00695c"
                            : config.env === "development"
                            ? "#0d47a1"
                            : "#424242",
                      }}
                      size="2x"
                    />
                    &emsp;
                    <MDBIcon icon="check" style={{ color: this.state.color.rulePrice }} size="1x" />
                    <br />
                    Reglas de precios
                  </MDBCol>
                  <MDBCol>
                    <MDBIcon
                      icon="cogs"
                      style={{
                        color:
                          config.env === "production"
                            ? "#00695c"
                            : config.env === "development"
                            ? "#0d47a1"
                            : "#424242",
                      }}
                      size="2x"
                    />
                    &emsp;
                    <MDBIcon icon="check" style={{ color: this.state.color.config }} size="1x" />
                    <br /> Configuracion de campos
                  </MDBCol>
                </MDBRow>
                <MDBProgress
                  value={this.state.progress}
                  color={config.color}
                  className="my-1 text-white white"
                />
              </div>
            </MDBModalBody>
            <MDBModalFooter></MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}

export default cardForm;
