import React, { Component } from "react";
import { Backend, Configuration, Form, Roles, Softexpert } from "../../data/index";
import { toast, ToastContainer } from "react-toastify";
import {
  MDBBtn,
  MDBContainer,
  MDBDataTable,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import config from "../../config";
import Fields from "./fields";
import "react-toastify/dist/ReactToastify.css";
import { convertConfigurations } from "../../Helpers/helpers";

const { GetFormularyById, GetAllFormularyDataTable } = Form;
const { saveConfigurations, GetConfigurations, updateConfigurations } = Configuration;
const { GetFormularyByServiceBK } = Backend;
const { GetForms } = Softexpert;
const { GetRolesByUserAndForm } = Roles;

class Configurations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      modalLoading: false,
      modal: false,
      json: [],
      config: [],
      idForm: "",
      update: false,
      idConfig: "",
      arrayGrid: [],
      errors: [],
    };

    this.create = this.create.bind(this);
    this.selectedCheck = this.selectedCheck.bind(this);
    this.arrayGrid = this.arrayGrid.bind(this);
  }

  async componentDidMount() {
    const dataGetter = await GetAllFormularyDataTable();
    if (dataGetter.ok) {
      this.setState({
        data: this.table(dataGetter.data.Formulary),
      });
    }
  }

  arrayGrid(data) {
    this.setState({
      arrayGrid: data,
    });
  }

  async toggle(id) {
    if (this.state.modal) {
      this.setState({
        modal: !this.state.modal,
        modalLoading: false,
      });
      return;
    }

    const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"), id);
    if (!roles.data.rol) {
      this.setState({
        progress: 0,
      });

      toast.error("Usted no tiene permiso para ver este formulario", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    this.setState({
      modal: true,
      idForm: id,
    });

    const dataForm = await GetFormularyById(id);

    let form = dataForm.data.Formulary[0];

    let data =
      form.provider === "bk"
        ? await GetFormularyByServiceBK(form.idservice)
        : await GetForms(form.idForm);
    if (form.provider === "se" && !data.ok) {
      console.log("data", data);
      return this.setState({
        errors: data,
        modal: false,
      });
    }

    if (data) {
      this.setState({
        json: form.provider === "bk" ? [JSON.parse(data.data.Form.fields)] : data.data,
        modalLoading: false,
      });
    }

    const resultConfig = await GetConfigurations(id);

    if (resultConfig.data.configurations) {
      let data = convertConfigurations(resultConfig);

      this.setState({
        config: data.json,
        update: true,
        arrayGrid: data.arrayGrid,
        idConfig: resultConfig.data.configurations.id,
      });
      this.selectedCheck(data.json);
    }

    if (!resultConfig.data.configurations) {
      this.setState({
        update: false,
      });
    }
  }

  async selectedCheckGrid(json) {
    for (const key in json) {
      let required = await document.getElementById(key + "required");
      let disabled = await document.getElementById(key + "disabled");
      let hidden = await document.getElementById(key + "hidden");
      if (required) {
        if (json[key].require) {
          required.checked = true;
          disabled.setAttribute("disabled", "disabled");
          hidden.setAttribute("disabled", "disabled");
        }

        if (!json[key].require) {
          required.checked = false;
        }
      }

      if (disabled) {
        if (json[key].disabled) {
          disabled.checked = true;
          required.setAttribute("disabled", "disabled");
        }

        if (!json[key].disabled) {
          disabled.checked = false;
        }
      }

      if (hidden) {
        if (json[key].hidden) {
          hidden.checked = true;
          required.setAttribute("disabled", "disabled");
        }

        if (!json[key].hidden) {
          hidden.checked = false;
        }
      }
    }
  }

  async selectedCheckField(json, key) {
    let required = await document.getElementById(key + "required");
    let disabled = await document.getElementById(key + "disabled");
    let hidden = await document.getElementById(key + "hidden");
    if (required) {
      if (json[key].require) {
        required.checked = true;
        disabled.setAttribute("disabled", "disabled");
        hidden.setAttribute("disabled", "disabled");
      }

      if (!json[key].require) {
        required.checked = false;
      }
    }

    if (disabled) {
      if (json[key].disabled) {
        disabled.checked = true;
        required.setAttribute("disabled", "disabled");
      }

      if (!json[key].disabled) {
        disabled.checked = false;
      }
    }

    if (hidden) {
      if (json[key].hidden) {
        hidden.checked = true;
        required.setAttribute("disabled", "disabled");
      }

      if (!json[key].hidden) {
        hidden.checked = false;
      }
    }
  }

  async selectedCheck(json) {
    for (const key in json) {
      if (Array.isArray(json[key])) {
        await this.selectedCheckGrid(json[key]);
      } else {
        await this.selectedCheckField(json, key);
      }
    }
  }

  table(data) {
    let array = [];
    for (const i of data) {
      let element = {
        id: i.id,
        idForm: i.idForm,
        logo: (
          <img
            className={" hoverable img-fluid"}
            alt=""
            width="80"
            src={i.url ? i.url : require("../../img/test.png")}
          />
        ),
        service: i.service_name,
        institution: i.institution_name,
        Accion: (
          <MDBBtn
            className="btn  btn-md text-white"
            color={config.color}
            onClick={() => this.toggle(i.id, i.idForm)}
          >
            configurar campos
          </MDBBtn>
        ),
      };
      array.push(element);
    }
    const column = [
      { label: "id", field: "id", sort: "asc" },
      { label: "formulario", field: "idForm", sort: "asc" },
      { label: "logo", field: "logo", sort: "asc" },
      { label: "Servicio", field: "service", sort: "asc" },
      { label: "Institucion", field: "institution", sort: "asc" },
      { label: "Accion", field: "Accion", sort: "asc" },
    ];

    return { columns: column, rows: array };
  }

  create(field, type, nameGrid, isGrid = false) {
    let element_required = document.getElementById(field + "required");
    let element_disabled = document.getElementById(field + "disabled");
    let element_hidden = document.getElementById(field + "hidden");

    if (isGrid) {
      this.createConfigByGrid(field, element_required, element_hidden, element_disabled);
    } else {
      this.createConfig(field, element_required, element_hidden, element_disabled, type, nameGrid);
    }

    if (element_required.checked) {
      element_disabled.setAttribute("disabled", "disabled");
      element_hidden.setAttribute("disabled", "disabled");
      return;
    } else {
      element_disabled.removeAttribute("disabled");
      element_hidden.removeAttribute("disabled");
    }

    if (element_hidden.checked) {
      element_required.setAttribute("disabled", "disabled");

      return;
    } else {
      element_required.removeAttribute("disabled");
    }

    if (element_disabled.checked) {
      element_required.setAttribute("disabled", "disabled");
    } else {
      element_required.removeAttribute("disabled");
    }
  }

  createConfigByGrid(field, required, hidden, disabled) {
    let array = this.state.config;
    array[field] = {
      require: !!required.checked,
      hidden: !!hidden.checked,
      disabled: !!disabled.checked,
    };

    this.setState({
      config: array,
    });
  }

  createConfig(field, required, hidden, disabled, type, nameGrid) {
    let array = this.state.config;
    let arrayFieldGrid = this.state.config[nameGrid + "_fields"];

    if (!arrayFieldGrid && type === "grid") {
      this.state.config[nameGrid + "_fields"] = [];
      arrayFieldGrid = this.state.config[nameGrid + "_fields"];
    }
    let json = {
      require: !!required.checked,
      hidden: !!hidden.checked,
      disabled: !!disabled.checked,
    };

    if (type === "grid") {
      if (arrayFieldGrid) {
        arrayFieldGrid[field] = json;

        array[nameGrid + "_fields"] = arrayFieldGrid;
      }
      array[field] = json;
    } else {
      array[field] = json;
    }

    this.setState({
      config: array,
      arrayGrid: arrayFieldGrid,
    });
  }

  async saveConfig() {
    let data = this.state.config;
    let result = [];

    if (Object.keys(data).length === 0) {
      toast.info("tiene que seleccionarr una configuracion antes de guardar", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    for (const key in data) {
      let resultGrid = [];
      if (Array.isArray(data[key])) {
        for (const i in data[key]) {
          resultGrid.push('"' + i + '":' + JSON.stringify(data[key][i]));
        }
        result.push('"' + key + '":(' + resultGrid + ")");
      } else {
        result.push('"' + key + '":' + JSON.stringify(data[key]));
      }
    }

    let body = {
      config: result.join("&"),
      idForm: this.state.idForm,
    };

    const response = await saveConfigurations(body);

    if (response.data.configurations) {
      toast.success("Se guardo la configuracion de los campos", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error("ups!, ocurrio un error al guardar", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      modal: false,
    });
  }

  async updateConfig() {
    let data = this.state.config;
    let result = [];

    for (const key in data) {
      let resultGrid = [];
      if (Array.isArray(data[key])) {
        for (const i in data[key]) {
          resultGrid.push('"' + i + '":' + JSON.stringify(data[key][i]));
        }
        result.push('"' + key + '":(' + resultGrid + ")");
      } else {
        result.push('"' + key + '":' + JSON.stringify(data[key]));
      }
    }

    let body = {
      config: result.join("&"),
    };
    const response = await updateConfigurations(body, this.state.idConfig);

    if (response.data.result) {
      toast.success("Se actualizo la configuracion de los campos", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error("ups!, ocurrio un error al actualizar", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    this.setState({
      modal: false,
    });
  }

  render() {
    return (
      <div className="App padding">
        <ToastContainer />

        <h2>Configuracion de Formularios</h2>
        <br />
        {this.state.errors.error && (
          <div>
            <h4 className="text-danger">
              <b>{this.state.errors.error}</b>
            </h4>
            <h5 className="text-danger">
              <b>{this.state.errors.data}</b>
            </h5>
            <h6 className="text-danger">
              <b>
                {"CAMPO: " +
                  this.state.errors.field.name +
                  "|| LABEL:" +
                  this.state.errors.field.label}
              </b>
            </h6>
          </div>
        )}
        <MDBContainer>
          {this.state.data && this.state.data.length === 0 && (
            <div className="col-md-12">
              <MDBIcon
                icon="cog"
                spin
                size="3x"
                style={{
                  color:
                    config.env === "production"
                      ? "#00695c"
                      : config.env === "development"
                      ? "#0d47a1"
                      : "#424242",
                }}
                fixed
              />
              <br></br>
              <br></br>
              <b>
                <h3>Cargando...</h3>
              </b>
            </div>
          )}
          {!!this.state.data.rows && <MDBDataTable bordered hover data={this.state.data} />}
        </MDBContainer>

        {/**===============================================MODAL================================================= */}
        <MDBContainer>
          <MDBModal isOpen={this.state.modal} toggle={() => this.toggle()} size="lg">
            <MDBModalHeader toggle={() => this.toggle()}>
              Selecione sus configuraciones
            </MDBModalHeader>
            <MDBModalBody id="modal-body-card-form">
              {this.state.json ? (
                this.state.json.length === 0 && (
                  <div>
                    <MDBIcon
                      icon="cog"
                      spin
                      size="2x"
                      style={{
                        color:
                          config.env === "production"
                            ? "#00695c"
                            : config.env === "development"
                            ? "#0d47a1"
                            : "#424242",
                      }}
                      fixed
                    />
                    <label>cargando....</label>
                  </div>
                )
              ) : (
                <h4>Este formulario tiene errores</h4>
              )}
              {this.state.json &&
                this.state.json.length > 0 &&
                this.state.json.map((pages, i) => (
                  <div key={"group-" + i}>
                    <h5>
                      <b>Pagina: {i + 1}</b>
                    </h5>
                    <hr />
                    <MDBTable key={i} bordered>
                      <MDBTableHead>
                        <tr>
                          <th>Campo</th>
                          <th>Requerido</th>
                          <th>Oculto</th>
                          <th>Deshabilitado</th>
                        </tr>
                      </MDBTableHead>
                      <MDBTableBody>
                        {pages.length > 0 &&
                          pages.map((field, key) => (
                            <tr key={"tr-" + key}>
                              <td>
                                <Fields
                                  arrayGrid={this.arrayGrid}
                                  create={this.create}
                                  json={this.state.config}
                                  selectedCheck={this.selectedCheck}
                                  config={true}
                                  field={field}
                                  isRules={false}
                                />
                              </td>

                              <td>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id={field.name + "required"}
                                    hidden={field.type === "header" || field.type === "subtitle"}
                                    onClick={() =>
                                      field.type === "grid"
                                        ? this.create(field.name, field.type, "", true)
                                        : this.create(field.name, field.type)
                                    }
                                  />{" "}
                                  <br />
                                  Requerido
                                </div>
                              </td>

                              <td>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id={field.name + "hidden"}
                                    onClick={() =>
                                      field.type === "grid"
                                        ? this.create(field.name, field.type, "", true)
                                        : this.create(field.name, field.type)
                                    }
                                  />
                                  <br />
                                  Oculto
                                </div>
                              </td>

                              <td>
                                <div className="form-group">
                                  <input
                                    type="checkbox"
                                    id={field.name + "disabled"}
                                    hidden={field.type === "header" || field.type === "subtitle"}
                                    onClick={() =>
                                      field.type === "grid"
                                        ? this.create(field.name, field.type, "", true)
                                        : this.create(field.name, field.type)
                                    }
                                  />
                                  <br />
                                  Deshabilitado
                                </div>
                              </td>
                            </tr>
                          ))}
                      </MDBTableBody>
                    </MDBTable>
                  </div>
                ))}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color={config.color}
                onClick={() => (this.state.update ? this.updateConfig() : this.saveConfig())}
              >
                Guardar Configuracion
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    );
  }
}

export default Configurations;
