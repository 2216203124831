import React, { Component } from "react";
import { MDBBtn, MDBBtnGroup, MDBCol, MDBDataTable, MDBIcon } from "mdbreact";
import { toast, ToastContainer } from "react-toastify";
import ModalUpdate from "./modalUpdate";
import ModalRol from "./modalRol";
import config from "../../config";
import { Configuration, Form, Mask, Rules, RulesInit, RulesPrice } from "../../data/index";

const { GetAllFormularyDataTable, deleteFormularyById } = Form;
const { deleteMaskByForm } = Mask;
const { deleteRulesInitByForm } = RulesInit;
const { deleteRulesByForm } = Rules;
const { deleteConfigurationsByForm } = Configuration;
const { deleteRulesPriceByForm } = RulesPrice;

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllForm: [],
      loading: false,
      modal: false,
      modalR: false,
      idForm: "",
      id: "",
    };

    this.toggle = this.toggle.bind(this);
    this.modalRol = this.modalRol.bind(this);
  }

  toggle(id, idForm) {
    this.setState({
      modal: !this.state.modal,
      idForm: idForm,
      id: id,
    });
  }

  modalRol() {
    console.log("this.state :", this.state);
    this.setState({
      modalR: !this.state.modalR,
    });
  }

  async delete(id, idForm) {
    let response = window.confirm(
      "Seguro que quiere eliminar el formulario [" + idForm + "] del ambiente: [" + config.env + "]"
    );
    if (!response) {
      return;
    }

    const rules = await deleteRulesByForm(id);
    const mask = await deleteMaskByForm(id);
    const rulesinit = await deleteRulesInitByForm(id);
    const result = await deleteFormularyById(id);
    await deleteRulesPriceByForm(id);
    await deleteConfigurationsByForm(id);
    if (result.data.err) {
      toast.error("El formulario " + idForm + " no se pudo borrar", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      if (!rules.data.err && !mask.data.err && !rulesinit.data.err) {
        toast.success("Se eliminaron todas las reglas y mascara del formulario ", idForm, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      toast.success("Se borro correctamente el formulario", idForm, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      await this.showDataTable();
    }
  }

  async componentDidMount() {
    await this.showDataTable();
  }

  async showDataTable() {
    this.setState({
      loading: true,
    });
    const data = await GetAllFormularyDataTable();
    let array = [];
    try {
      for (const i of data.data.Formulary) {
        let element = {
          id: i.id,
          idForm: i.idForm,
          service: i.service_name,
          institution: i.institution_name,
          Accion: (
            <MDBBtnGroup className="mr-2" size="sm">
              <MDBBtn color="primary" onClick={(e) => this.toggle(i.id, i.idForm)}>
                Editar
              </MDBBtn>
              <MDBBtn color="danger" onClick={(e) => this.delete(i.id, i.idForm)}>
                Eliminar
              </MDBBtn>
            </MDBBtnGroup>
          ),
        };
        array.push(element);
      }
    } catch (e) {
      console.log(e);
    }
    const column = [
      { label: "id", field: "id", sort: "asc" },
      { label: "formulario", field: "idForm", sort: "asc" },
      { label: "logo", field: "logo", sort: "asc" },
      { label: "Servicio", field: "service", sort: "asc" },
      { label: "Institucion", field: "institution", sort: "asc" },
      { label: "Accion", field: "Accion", sort: "asc" },
    ];
    const result = { columns: column, rows: array };
    this.setState({
      AllForm: result,
      loading: false,
    });
  }

  render() {
    return (
      <div className="App">
        <ToastContainer />
        <br />
        <br />
        <h1>Formularios</h1>
        <h5>Ambiente: {config.env}</h5>

        <MDBCol size="4">
          <MDBBtn
            style={{ marginLeft: "-40%" }}
            onClick={() => this.modalRol()}
            className="btn btn-sm "
            color={config.color}
          >
            cambiar rol de usuario
          </MDBBtn>
        </MDBCol>
        <MDBCol size="4"></MDBCol>
        <MDBCol size="4"></MDBCol>
        {this.state.AllForm.rows && (
          <MDBDataTable striped bordered hover data={this.state.AllForm} />
        )}
        {this.state.loading ? (
          <MDBIcon
            icon="cog"
            spin
            size="3x"
            style={{
              color:
                config.env === "production"
                  ? "#00695c"
                  : config.env === "development"
                  ? "#0d47a1"
                  : "#424242",
            }}
            fixed
          />
        ) : (
          ""
        )}
        <ModalUpdate
          idForm={this.state.idForm}
          id={this.state.id}
          toggle={this.toggle}
          modal={this.state.modal}
        />
        <ModalRol modal={this.state.modalR} toggle={this.modalRol} />
      </div>
    );
  }
}

export default Admin;
